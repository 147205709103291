@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/montserrat-medium.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/montserrat-semiBold.woff2') format('woff2');
}

@layer base {
  html {
    font-family: montserrat, sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.prose h3 {
  @apply text-2xl font-semibold;
}

.prose :is(img, p):not(li > *) {
  @apply my-6;
}

.prose li :is(p) {
  @apply my-2;
}

.prose p {
  @apply md:text-lg leading-relaxed;
}

.prose p,
.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6,
.prose span {
  @apply text-white;
}

.prose .richText :is(h1, h2, h3, h4, h5, h6) {
  @apply mb-0;
}

p:empty {
  @apply m-0;
}

.prose.case span,
.prose.case img {
  @apply my-0;
}

.prose a {
  @apply text-primary;
}

.prose a:is(:focus, :hover) {
  @apply text-secondary;
}

.prose.case h1,
.prose.case h2,
.prose.case h3,
.prose.case h4,
.prose.case h5,
.prose.case h6 {
  @apply mt-0;
}

.prose :is(ul, ol) li::marker {
  @apply text-white;
}

.deliverables .prose ul {
  padding-left: 0;
}

.deliverables .prose p {
  margin-top: 0;
  margin-bottom: 0;
}

.deliverables li {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.deliverables li p {
  margin: 0;
  padding: 0;
  width: -webkit-fill-available;
}

.deliverables li::before {
  content: '';
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  margin-right: 0.5em;
  background-image: url('assets/img/logo-without-text.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .deliverables li p {
    font-size: 16px !important;
  }

  .deliverables li::before {
    width: 2em;
    height: 2em;
  }
}

.scroll-animation::after {
  content: '';
  position: absolute;
  @apply ml-2;
  @apply bg-white;
  @apply h-[2px];
  @apply animate-scroll-pulse;
  @apply ease-in-out;
  @apply duration-300;
  @apply translate-y-[-50%];
  @apply top-[50%];
}

/* Remove accidently added breaks from Storyblok and use fancy CSS because it now works */
.prose :is(p, b, h1, h2, h3, h4, h5, h6, span):empty,
.prose :is(p, b, h1, h2, h3, h4, h5, h6, span):only-child:has(br) {
    display: none;
}

/* Remove spacing from the image-gallery if there is a paragraph in front */
.prose .richText:has(> p) + .image-gallery img {
  margin-top: 0;
}
